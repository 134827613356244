/**
 * Cookie Toast
 */
var cookieToastEl = document.getElementById('cookie-toast');
var cookieToast = bootstrap.Toast.getOrCreateInstance(cookieToastEl);

if (!localStorage.getItem('shown.cookie.toast')) {
  cookieToast.show();

  cookieToastEl.addEventListener('hidden.bs.toast', function () {
    localStorage.setItem('shown.cookie.toast', true);
  });
}
