import './owl.carousel';

/**
 * Carousels
 */
(function ($) {
  $('#partners-carousel').owlCarousel({
    autoplay: true,
    loop: true,
    margin: 20,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      992: {
        items: 4,
      },
    },
  });

})(jQuery);
