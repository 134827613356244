/**
 * Navbar
 * Change transparent background to solid color on scroll.
 */
(function ($) {
  var navbar = $('.navbar');
  var scrollTop = $(window).scrollTop();

  if (scrollTop >= 50) {
    navbar.removeClass(['bg-transparent']);
    navbar.addClass(['bg-primary', 'shadow-sm']);
  }

  $(window).scroll(function () {
    scrollTop = $(window).scrollTop();

    if (scrollTop >= 50) {
      navbar.removeClass(['bg-transparent']);
      navbar.addClass(['bg-primary', 'shadow-sm']);
    } else {
      navbar.removeClass(['bg-primary', 'shadow-sm']);
      navbar.addClass(['bg-transparent']);
    }
  });

  // Collapse
  var navbarCollapse = $('.navbar-collapse');

  navbarCollapse.on('show.bs.collapse', function () {
    navbar.removeClass(['bg-transparent']);
    navbar.addClass(['bg-primary', 'shadow-sm']);
  });

  navbarCollapse.on('hide.bs.collapse', function () {
    if (scrollTop <= 50) {
      navbar.removeClass(['bg-primary', 'shadow-sm']);
      navbar.addClass(['bg-transparent']);
    }
  });

})(jQuery);
